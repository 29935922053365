// 遗产要素清单
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列 
            columnsData: [{
                prop: 'YCYSFL_1',
                label: '片区',
                minWidth: 105,
                showoverflowtooltip: true
            }, {
                prop: 'YCYSFL_2',
                label: '遗产组成',
                minWidth: 105,
                showoverflowtooltip: true
            }, {
                prop: 'MC',
                label: '遗产要素名称',
                minWidth: 105,
                showoverflowtooltip: true
            }, {
                prop: 'YCYSLXMC',
                minWidth: 105,
                label: '遗产要素类型'
            }, {
                prop: 'YCYSMS',
                label: '遗产要素描述',
                minWidth: 105,
                showoverflowtooltip: true
            }, {
                prop: 'GMLX',
                prop1: 'GM',
                unit: '㎡',
                label: '规模',
            }, {
                prop: 'DBXTP',
                label: '示意图',
                showImg: true,
            }],
            // 操作列
            columnsBtn: {
                buttonData: []
            },
        },
    },
    snSearch: {
        selectData: [{
            label: '遗产要素类型',
            clearable: true,
            placeholder: "请选择遗产要素类型",
            list: [{
                ID: '1',
                NAME: '建/构筑物'
            }, {
                ID: '2',
                NAME: '遗址/墓葬'
            }, {
                ID: '3',
                NAME: '洞窟/龛'
            }, {
                ID: '4',
                NAME: '造像/雕塑/碑刻/题刻/壁画/彩画'
            }, {
                ID: '5',
                NAME: '山体'
            }, {
                ID: '6',
                NAME: '水体'
            }, {
                ID: '7',
                NAME: '植被'
            }, {
                ID: '8',
                NAME: '街区'
            }, {
                ID: '9',
                NAME: '其他'
            }],
            optionLabel: 'NAME',
            optionValue: 'ID',
            value: 'YCYSLX',
            operateType: 'search',
            isShow: true
        }]
    }
};
export default selfData;